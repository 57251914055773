import React, { useState, useEffect } from 'react';
import Lenis from '@studio-freight/lenis';
import { ContextLoader, ContextSequences } from '../context';
import loadSequence from '../helpers/loadSequence';
import Loader from './Loader';
import Nav from './Nav';
// import Transition from './Transition';
import Seo from './Seo';
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter';
import { GlobalEventBus } from '../helpers/eventBus';
import '../styles/style.scss';
import '../helpers/noSSR';

const Layout = ({ children, location, data }) => {
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Virtual scroll
     */
    useEffect(() => {
        const lenis = new Lenis({ duration: 1.7 });

        const raf = time => {
            lenis.raf(time);
            requestAnimationFrame(raf);
        };

        const handleScrollPause = () => {
            lenis.stop();
        };

        const handleScrollPlay = () => {
            lenis.start();
        };

        GlobalEventBus.on('onScrollPause', handleScrollPause);
        GlobalEventBus.on('onScrollPlay', handleScrollPlay);

        requestAnimationFrame(raf);
    }, []);

    /*
     * Preload sequences images
     */
    const sequencesDefault = {
        sdLogowhite: [],
        sdLogoblack: [],
        sdHome: [],
        mobileHome: [],
        sdTechnology: [],
        mobileTechnology: [],
    };
    const [sequences, setSequences] = useState(sequencesDefault);
    useEffect(() => {
        // If the first load of the site is not the technology page, load in first the home sequence then the technology one.
        if (location !== '/technology/') {
            // If we are on mobile, load the mobile version
            if (localStorage.getItem('is-touch') === 'true' && window.innerWidth < 1025) {
                // loadSequence('home', '750', 130, 'webp').then(sequence => {
                // setSequences(prevState => ({ ...prevState, mobileHome: sequence }));
                loadSequence('technology', '600', 347, 'webp').then(sequence => {
                    setSequences(prevState => ({ ...prevState, mobileTechnology: sequence }));
                });
                // });
            } else {
                // loadSequence('home', '1920', 130, 'webp').then(sequence => {
                // setSequences(prevState => ({ ...prevState, sdHome: sequence }));
                loadSequence('technology', '1600', 347, 'webp').then(sequence => {
                    setSequences(prevState => ({ ...prevState, sdTechnology: sequence }));
                });
                // });
            }
        }
        // If we are on the technology page
        else {
            // If we are on mobile, load the mobile version
            if (localStorage.getItem('is-touch') === 'true' && window.innerWidth < 1025) {
                loadSequence('technology', '600', 347, 'webp').then(sequence => {
                    setSequences(prevState => ({ ...prevState, mobileTechnology: sequence }));
                    // loadSequence('home', '600', 150, 'webp').then(sequence => {
                    //     setSequences(prevState => ({ ...prevState, mobileHome: sequence }));
                    // });
                });
            } else {
                loadSequence('technology', '1600', 347, 'webp').then(sequence => {
                    setSequences(prevState => ({ ...prevState, sdTechnology: sequence }));
                    // loadSequence('home', '1600', 150, 'webp').then(sequence => {
                    //     setSequences(prevState => ({ ...prevState, sdHome: sequence }));
                    // });
                });
            }
        }
    }, []);

    // Seo
    const seoFormattedPageName = getSeoFormattedPageName(location.pathname);
    const metaDescription = data.prismicSeo ? data.prismicSeo.data.meta_description.text : '';

    return (
        <div
            className={`MoxionPower ${location.pathname.replaceAll('/', '')} ${
                location.pathname === '/' ? 'home' : ''
            }`}
        >
            <ContextLoader.Provider value={[isLoading, setIsLoading]}>
                <ContextSequences.Provider value={[sequences, setSequences]}>
                    <Loader />
                    <Nav pathname={location.pathname} />
                    <Seo title={seoFormattedPageName} description={metaDescription} />

                    {/* Transition between pages is quite jarring removing for now */}
                    {/* <Transition location={location}> */}
                    <div className={`MoxionPower__Page ${isLoading === false ? 'isLoaded' : ''}`}>{children}</div>
                    {/* </Transition> */}
                </ContextSequences.Provider>
            </ContextLoader.Provider>
        </div>
    );
};

const getSeoFormattedPageName = pathname => {
    if (pathname === '/') {
        return '';
    } else {
        const pageName = pathname.replaceAll('/', '');
        return capitalizeFirstLetter(pageName);
    }
};

export default Layout;
