import detectBrowser from './detectBrowser';
import detectOs from './detectOs';

if (typeof window !== `undefined`) {
    // Set local storage is-touch
    if ('ontouchstart' in document.documentElement) {
        localStorage.setItem('is-touch', 'true');
        document.querySelector('html').classList.add('is-touch');
    } else {
        localStorage.setItem('is-touch', 'false');
    }

    // Set local storage browser and os
    const browser = detectBrowser();
    const os = detectOs();
    localStorage.setItem('browser', browser);
    localStorage.setItem('os', os);

    let svh = null;
    let lvh = null;

    // 100vh bug for phones
    window.onresize = function () {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const currentVh = window.innerHeight * 0.01;

        svh = Math.min(currentVh, svh || currentVh);
        lvh = Math.max(currentVh, lvh || currentVh);

        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${lvh}px`);
        document.documentElement.style.setProperty('--dvh', `${currentVh}px`);
        document.documentElement.style.setProperty('--svh', `${svh}px`);
    };
    window.onresize();
}
