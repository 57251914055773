import { CDN_BASE_URL } from '../utils/variables'
import loadImage from './loadImage'

const loadSequence = (folder, quality, framesCount = 150, defaultFileExt = 'jpg') => {
	let framesLoaded = 0
	let sequenceFrames = []
	return new Promise(resolve => {
		for (let i = 1; i <= framesCount; i += 1) {
			let fileExt = defaultFileExt
			loadImage(
				`${CDN_BASE_URL}/${folder}/${quality}/${i.toString().padStart(3, '0')}.${fileExt}`
			)
			.then(img => {
				framesLoaded += 1
				sequenceFrames[i] = img
				if (framesLoaded === framesCount) {
					resolve(sequenceFrames)
				}
			})
		}
	})
}

export default loadSequence
