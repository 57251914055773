const FontFaceObserver = require('fontfaceobserver')

const fontsData = {
  'Inter': { weight: 'normal' },
  'Inter Bold': { weight: 'bold' }
};

const defaultFonts = [
  'Inter',
  'Inter Bold'
]

function loadFonts(fonts = defaultFonts) {
  return new Promise((resolve) => {
    const fontFaceObersvers = fonts.map(font => {
      const data = fontsData[font]
      return new FontFaceObserver(font, data)
    })
    Promise.all(fontFaceObersvers).then(() => {
      resolve()
    });
  })
}

export default loadFonts
