const loadImage = url => {
	return new Promise(resolve => {
		const img = new Image()
		img.src = url
		if (img.complete === true) {
			resolve(img)
		}
		img.addEventListener('load', () => {
			resolve(img)
		})
	})
}

export default loadImage
