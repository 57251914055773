import React, { useEffect, useContext, useState } from 'react';
import { ContextLoader } from '../context';
import loadFonts from '../helpers/loadFonts';

const Loader = () => {
    const LOADER_END_ANIM_TIME = 450;

    const [isLoading, setIsLoading] = useContext(ContextLoader);
    const [isLoaderEnding, setIsLoaderEnding] = useState(false);
    const [areAssetsLoaded, setAreAssetsLoaded] = useState(false);

    // Handle loader ended
    useEffect(() => {
        if (areAssetsLoaded === true) {
            setIsLoaderEnding(true);
            setTimeout(() => {
                setIsLoading(false);
            }, LOADER_END_ANIM_TIME);
        }
    }, [areAssetsLoaded]);

    // Launch assets loading
    useEffect(() => {
        handleFontsLoad().then(() => {
            setAreAssetsLoaded(true);
        });
    }, [setIsLoading]);

    // Load fonts
    const handleFontsLoad = () => {
        return new Promise(resolve => {
            loadFonts().then(() => {
                resolve();
            });
        });
    };

    return (
        <div className={`Loader ${isLoaderEnding === true ? 'isEnding' : ''} ${isLoading === false ? 'isEnded' : ''}`}>
            <div className="Loader__Side Loader__Left"></div>
            <div className="Loader__Side Loader__Right"></div>
            <div className="Loader__Side Loader__Top"></div>
            <div className="Loader__Side Loader__Bottom"></div>

            <svg className="Loader__Logo" viewBox="0 0 98 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M95.3249 29.7111H82.0691C80.6129 29.7111 79.4332 30.9383 79.4332 32.4523V54.824C79.4332 56.1202 77.9318 56.7765 77.0415 55.8696L26.2525 4.14119C23.6452 1.48659 20.1427 0 16.4943 0H2.70631C0.285778 0 -0.911727 3.05527 0.816153 4.81642C1.59238 5.60664 2.33018 6.35932 2.91216 6.95068C7.40289 11.5251 20.678 25.0491 26.8133 31.2964C28.2262 32.7349 28.2247 35.0865 26.8118 36.5251L0.971971 62.8561C-0.874833 64.737 0.406245 68 2.99084 68C2.99084 68 14.2357 67.9969 19.4227 67.9961C21.067 67.9953 22.6455 67.325 23.8206 66.1282L40.368 49.2745C41.5062 48.1147 43.3288 48.1147 44.4669 49.2745L60.0326 65.1313L60.963 66.079C62.1719 67.3104 63.7968 68 65.4894 68H81.775C81.8183 68 81.8586 67.9919 81.9004 67.9901C81.9615 67.9948 82.0242 68 82.0869 68H95.3352C96.8082 68 98 66.7587 98 65.227V32.4944C98 30.957 96.803 29.7111 95.3249 29.7111Z"
                    fill="white"
                />
                <path
                    d="M95.3215 0H63.5824C62.914 0 62.2716 0.276069 61.7939 0.768787L52.76 10.0975C51.7464 11.1444 51.7464 12.8643 52.7616 13.9097L61.5276 22.9369C62.9045 24.3553 65.1043 24.3542 66.4795 22.9345L77.1757 11.897C77.9876 11.0602 79.3548 11.6655 79.3548 12.8619V18.7421C79.3548 20.3847 80.6184 21.7164 82.1776 21.7164H95.249C96.7696 21.7164 98 20.4191 98 18.8188V2.82116C98 1.26302 96.8006 0 95.3215 0Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default Loader;
