import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'gatsby';
import { ContextLoader } from '../context';
import lottie from 'lottie-web';
import logoAnimData from '../images/lottie/logo.json';

const Nav = ({ pathname = null }) => {
    const [isLoading] = useContext(ContextLoader);

    /**
     * Logo animation
     */
    const logoContainerEl = useRef(null);
    const lottieLogoAnim = useRef(null);

    useEffect(() => {
        lottieLogoAnim.current = lottie.loadAnimation({
            container: logoContainerEl.current,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: logoAnimData,
        });

        return function clear() {
            lottieLogoAnim.current.destroy();
        };
    }, []);

    useEffect(() => {
        if (isLoading === false) {
            lottieLogoAnim.current.play();
        }
    }, [isLoading]);

    /**
     * Burger menu
     */

    const [isBurgerOpen, setIsBurgerOpen] = useState(false);

    const handleBurgerTriggerClick = () => {
        setIsBurgerOpen(!isBurgerOpen);
    };

    useEffect(() => {
        document.body.classList.toggle('noScroll', isBurgerOpen);
        document.body.classList.toggle('isBurgerOpen', isBurgerOpen);
    }, [isBurgerOpen]);

    const [year, setYear] = useState(null);
    useEffect(() => {
        setYear(new Date().getFullYear());
    }, []);

    /**
     * Scroll
     */
    const [isNavTop, setIsNavTop] = useState(true);
    const [isNavHidden, setIsNavHidden] = useState(false);
    const lastScrollY = useRef(0);

    useEffect(() => {
        let rafId = null;

        const handleScroll = () => {
            rafId = requestAnimationFrame(loop);
        };

        const loop = () => {
            const scrollY = window.scrollY < 0 ? 0 : window.scrollY;
            if (scrollY > lastScrollY.current && isNavHidden === false) {
                setIsNavHidden(true);
            } else if (scrollY < lastScrollY.current && isNavHidden === true) {
                setIsNavHidden(false);
            }
            lastScrollY.current = scrollY;
            if (scrollY < 100 && isNavTop === false) {
                setIsNavTop(true);
            } else if (scrollY > 100 && isNavTop === true) {
                setIsNavTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            cancelAnimationFrame(rafId);
        };
    }, [isNavHidden]);

    /**
     * Nav color
     */
    const [isNavWhiteOnTop, setIsNavWhiteOnTop] = useState(false);
    useEffect(() => {
        const pageIsCareersOrTechnology =
            pathname.includes('careers') || pathname.includes('technology') || pathname.includes('customer-care');
        if (pageIsCareersOrTechnology === true && isNavWhiteOnTop === false) {
            setIsNavWhiteOnTop(true);
        } else if (pageIsCareersOrTechnology === false && isNavWhiteOnTop === true) {
            setIsNavWhiteOnTop(false);
        }
    }, [pathname, isNavWhiteOnTop]);

    return (
        <div
            className={`Nav ${isNavHidden ? 'isHidden' : ''} ${isNavTop ? 'isTop' : ''} ${
                isNavWhiteOnTop ? 'isWhiteOnTop' : ''
            }`}
        >
            <Link to="/" className="Nav__LogoContainer" ref={logoContainerEl}></Link>
            <ul className="Nav__Links">
                <li className="Nav__Link">
                    <Link to="/" className="Nav__LinkContainer" activeClassName="isCurrent">
                        Home
                    </Link>
                </li>
                <li className="Nav__Link">
                    <Link to="/technology" className="Nav__LinkContainer" activeClassName="isCurrent">
                        Technology
                    </Link>
                </li>
                <li className="Nav__Link">
                    <Link to="/industries" className="Nav__LinkContainer" activeClassName="isCurrent">
                        Industries
                    </Link>
                </li>
                <li className="Nav__Link">
                    <Link to="/mission" className="Nav__LinkContainer" activeClassName="isCurrent">
                        Mission
                    </Link>
                </li>
                <li className="Nav__Link">
                    <Link to="/careers" className="Nav__LinkContainer" activeClassName="isCurrent">
                        Careers
                    </Link>
                </li>
            </ul>
            <Link to="/contact" className="Nav__Contact">
                <div className="Nav__ContactArrow">
                    <svg className="Nav__ContactArrowIcon" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.63783 7.52265C9.87148 7.28251 10.1928 6.98071 10.6016 6.61725C10.7769 6.46148 10.8613 6.38684 10.8548 6.39333C10.4069 6.40631 9.84552 6.4128 9.17053 6.4128L0.535156 6.4128L0.535156 4.25152L9.17053 4.25152C9.83254 4.25152 10.394 4.25802 10.8548 4.271C10.5562 4.00489 10.2025 3.66091 9.7936 3.23903L9.59889 3.04432L6.9411 0.328125L9.9299 0.328125L14.5932 5.31269L9.9299 10.2973H6.9411L9.63783 7.52265Z" />
                    </svg>
                </div>
                <p className="Nav__ContactLabel">Contact</p>
            </Link>
            <div className="Nav__Burger">
                <div className="Nav__BurgerTrigger" onClick={handleBurgerTriggerClick}>
                    Menu
                </div>
                <div className={`Nav__BurgerContainer ${isBurgerOpen === true ? 'isOpen' : ''}`}>
                    <svg
                        className="Nav__BurgerLines"
                        viewBox="0 0 430 148"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M-585.813 137.273L-585.813 137.273L-585.813 137.259L-585.828 -91.2392C-585.828 -91.2392 -585.828 -91.2392 -585.828 -91.2393C-585.828 -96.5065 -581.69 -100.762 -576.601 -100.762H-519.717C-506.221 -100.762 -493.801 -93.1574 -487.343 -80.9307L-416.235 53.6821C-412.311 61.1106 -401.962 61.1106 -398.038 53.6821L-326.932 -80.9307C-320.472 -93.1575 -308.052 -100.762 -294.557 -100.762L-27.5841 -100.891C-6.0979 -100.891 11.3088 -83.8365 11.3088 -62.8095V108.686C11.3088 129.665 -6.05817 146.679 -27.4944 146.679H-261.123C-282.56 146.679 -299.928 129.665 -299.928 108.686V27.9827V27.8681L-300.015 27.7934C-302.15 25.9533 -305.729 26.1314 -307.408 29.0913L-369.355 138.265C-369.355 138.265 -369.355 138.266 -369.356 138.267C-372.816 144.155 -379.005 147.749 -385.676 147.749H-428.598C-435.269 147.749 -441.459 144.155 -444.919 138.265L-445.135 138.391L-444.919 138.265L-508.747 29.6893C-511.312 25.3257 -517.797 27.2067 -517.819 32.2722L-518.264 133.182C-518.299 141.245 -524.647 147.749 -532.449 147.749H-576.226C-581.738 147.749 -586.13 142.966 -585.813 137.273ZM-79.3456 97.6797C-66.6649 97.6797 -56.3769 87.6111 -56.3769 75.1817V-24.5799C-56.3769 -37.0388 -66.6895 -47.1298 -79.3986 -47.1298H-147.301H-215.201C-227.911 -47.1298 -238.223 -37.0388 -238.223 -24.5799V75.1817C-238.223 87.6111 -227.934 97.6797 -215.253 97.6797H-147.301H-79.3456Z"
                            stroke="white"
                            strokeWidth="0.5"
                        />
                        <path
                            d="M705.302 -102.414L705.304 -102.414C720.209 -102.539 734.55 -96.541 745.166 -85.7424L861.435 32.5638L861.613 32.3886L861.435 32.5638C865.819 37.0228 873.216 33.8051 873.216 27.4938V-65.5609C873.216 -85.7378 889.086 -102.081 908.631 -102.081H936.905C940.72 -102.081 943.829 -98.8901 943.829 -94.936V126.298C943.829 137.895 934.717 147.28 923.491 147.28H886.937C882.269 147.28 877.786 145.4 874.448 142.039L874.271 142.215L874.448 142.039L741.844 8.72572L741.844 8.72537C740.069 6.94785 737.116 8.2557 737.116 10.7693V125.228C737.116 137.415 727.541 147.28 715.739 147.28L458.756 146.808H458.755C437.128 146.808 419.597 129.64 419.597 108.473V-64.5485C419.597 -85.765 437.166 -102.972 458.85 -102.972L705.302 -102.414ZM642.148 97.3667C654.939 97.3667 665.321 87.2103 665.321 74.6711V-25.98C665.321 -38.549 654.92 -48.7281 642.092 -48.7281H573.587H505.083C492.265 -48.7281 481.863 -38.549 481.863 -25.98V74.6711C481.863 87.2102 492.236 97.3667 505.036 97.3667H573.587H642.148Z"
                            stroke="white"
                            strokeWidth="0.5"
                        />
                        <path
                            d="M42.4334 146.738C42.4331 146.738 42.433 146.738 42.433 146.818V146.738C33.2001 146.738 28.5908 135.162 35.2129 128.474L35.0352 128.299L35.2129 128.474L129.897 32.8057C135.171 27.4819 135.177 18.7806 129.903 13.4565C112.674 -3.93843 80.0795 -36.8613 58.383 -58.7764C51.7685 -65.4575 46.1669 -71.1156 42.3223 -74.9987L42.3221 -74.9989C41.012 -76.3188 39.4865 -77.8599 37.8451 -79.518C36.8149 -80.5588 35.7389 -81.6457 34.642 -82.753C28.4553 -89.0054 32.759 -99.8267 41.3903 -99.8267H91.9134C105.215 -99.8267 117.985 -94.453 127.493 -84.8546L313.599 103.09C317.03 106.556 322.79 104.032 322.79 99.1156V17.8322C322.79 12.4622 327.008 8.12257 332.199 8.12257H380.772C386.043 8.12257 390.325 12.5301 390.325 17.9854V136.913C390.325 142.347 386.062 146.738 380.81 146.738H332.264C332.061 146.738 331.857 146.722 331.646 146.706L331.6 146.703L331.585 146.701L331.57 146.702C331.495 146.705 331.421 146.713 331.355 146.721L331.327 146.724C331.252 146.732 331.186 146.738 331.121 146.738H271.446C265.312 146.738 259.421 144.26 255.038 139.832L251.628 136.389L194.591 78.7765C190.323 74.4637 183.485 74.4637 179.216 78.7765L118.582 140.011C114.322 144.313 108.601 146.721 102.644 146.724C93.1408 146.725 78.088 146.729 65.411 146.732L49.1681 146.736L44.2405 146.737L42.9003 146.738L42.5517 146.738L42.4628 146.738L42.4404 146.738H42.4348H42.4334Z"
                            stroke="white"
                            strokeWidth="0.5"
                        />
                    </svg>
                    <div className="Nav__Close" onClick={handleBurgerTriggerClick}></div>
                    <ul className="Nav__BurgerLinks">
                        <li className="Nav__BurgerLink" onClick={handleBurgerTriggerClick}>
                            <Link to="/">Home</Link>
                        </li>
                        <li className="Nav__BurgerLink" onClick={handleBurgerTriggerClick}>
                            <Link to="/technology">Technology</Link>
                        </li>
                        <li className="Nav__BurgerLink" onClick={handleBurgerTriggerClick}>
                            <Link to="/industries">Industries</Link>
                        </li>
                        <li className="Nav__BurgerLink" onClick={handleBurgerTriggerClick}>
                            <Link to="/mission">Mission</Link>
                        </li>
                        <li className="Nav__BurgerLink" onClick={handleBurgerTriggerClick}>
                            <Link to="/careers">Careers</Link>
                        </li>
                        <li className="Nav__BurgerLink" onClick={handleBurgerTriggerClick}>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                    <div className="Nav__BurgerFooter">
                        <div className="Nav__BurgerFooterLeft">
                            <p>©{year} Moxion Power</p>
                            <p>
                                1414 Harbour Way S # 1800, <br /> Richmond, CA 94804, USA
                            </p>
                        </div>
                        <div className="Nav__BurgerFooterRight">
                            <p>
                                Manufactured in the USA
                                <img
                                    className="Nav__BurgerFooterFlag"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAYCAYAAACWTY9zAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIjSURBVHgBzZfPq2lRFMeX64j8uOTXROlFiiczZWpkZCYTAwxfytyQgfgLlGLwykDmokQYYnrfwORJTAhRfoX223u9nK56b3Iddb612Z8T66zzXWvv3ZHodDq/x+P5IZfLZfCkWq3WDZ7Xno6fEo7jPq7X63cQQIQQEEgTCYt3J+oanM9n+B/LZDK4XC48KxQKOJ1Or0gMuPskkUhAOByGUCgE6/Ua/H4/5HI5SKVS0Ov1wOfzQalUgmQyCd1uFywWC1QqFSgWi1CtVjFGJpMBocR9hsVigUkx7fd7GA6H0O/3kQeDAdTrdVitVsjz+Rym0ykYDAb+/+l0GoTSQynFJN4xl8sFgUAACoUC9pFWq8Uy5vN52G63yGzOrjFWKpUQi8Wg3W7DeDzGGEI6xsQcIzQoIX+7F0ckEkG2Wq3IbrcbORqN8r9hon31wEKJT8xsNhO1Ws3fRCqV8vPPgzrFz41GI1GpVC9J7O1uG2t8p9PJ23i73YC69GCt1+uFw+HAs91ux0XyKuHT0q2A0BVH9Ho9cjwex8zpqYBss9mQaV8hm0wmslwuSTab/aezzw4p/Uiz7Ha7HbrWaDQw2+PxiFtDrVZD3mw2+N1sNvE6c46WETqdDkwmExBa4t8uhBAR8kgSMpiQegORShIMBkVpmWibX7Sl5EajEYhREiLSZSneUpbL5clsNvsGIpLD4fgF7PVNo9F8wAsO4q+M93ftb5bTHzwy3vGfhUGhAAAAAElFTkSuQmCC"
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nav;
