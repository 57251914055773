const { GlobalEventBus } = require('./src/helpers/eventBus');

if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}

/**
 * Gatsby aggressively caches scroll position in order to be helpful and restore scroll on page
 * navigation but it doesn't appear to work very consisetently and gets in the way of basic
 * page routing especially when hashes are used to deep link to sections within a page.
 *
 * There doesn't appear to be a built in way to disable it in Gatsby. The recommendations have been
 * to return false form `shouldUpdateScroll` but this does not work and badly breaks hashed url routing.
 */

function isGatsbyScrollKey(key) {
    return /^@@scroll/.test(key);
}

function disableGatsbyScrollRestoration() {
    if (!this.ogSessionStorageSetItem) {
        this.ogSessionStorageSetItem = window.sessionStorage.setItem.bind(window.sessionStorage);
    }

    window.sessionStorage.setItem = (key, value) => {
        if (!isGatsbyScrollKey(key)) {
            this.ogSessionStorageSetItem(key, value);
        }
    };

    return () => {
        window.sessionStorage.setItem = this.ogSessionStorageSetItem;
    };
}

function resetStoredScrollPositions() {
    const sessionStorageLength = sessionStorage.length;

    for (let i = 0; i < sessionStorageLength; i += 1) {
        const key = sessionStorage.key(i);

        if (isGatsbyScrollKey(key)) {
            sessionStorage.removeItem(key);
        }
    }
}

disableGatsbyScrollRestoration();

exports.onInitialClientRender = () => {
    resetStoredScrollPositions();
};

exports.shouldUpdateScroll = () => {
    // Following a hashed link (link to section in page) was going to the right page but not to the right place in the page
    // ended up at the bottom of the page some of the time. This appears to happen when the link is clicked when the page
    // is being scrolled (due to inertia). Pausing and playing lenis (the scroll library) appears to fix this.
    GlobalEventBus.emit('onScrollPause');
    GlobalEventBus.emit('onScrollPlay');

    return [0, 0];
};
